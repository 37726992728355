<!--  -->
<template>
<div class="member">
    <img src="../../assets/img/membership/lr.png">
</div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {

        };
    },
    beforeCreate() {
        document.getElementById('app').setAttribute('style', 'background:#fff;width:auto; min-width: 1280px;min-height: auto;height:auto')
        document.querySelector('body').setAttribute('style', 'background:#fff;')
        document.getElementsByClassName('mainBody')[0].setAttribute('style', 'position: relative;')
    },
    //销毁前清除
    beforeDestroy() {
        document.getElementsByClassName('mainBody')[0].removeAttribute('style')
        document.getElementById('app').removeAttribute('style')
        document.querySelector('body').removeAttribute('style')
    },
    computed: {},
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.member {
  background: url('../../assets/img/membership/bg.jpg');
  background-size: 100% auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-y: hidden;

  img {
    width: 1280px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: -4%;
  }
}
</style>
